'use client';

import getLocal from '@/app/utils/getLocal';
import { useEffect } from 'react';
// 1小时有多少毫妙
const oneHoursTimes = 1000 * 60 * 60;
// 获取数组
const getStorageTimeArr = (timeKey: string) => {
    const str1 = localStorage.getItem(timeKey) || '';
    if (str1) {
        const arr1 = str1.split(',');
        return arr1;
    }
    return [];
};
// 设置数组
const setStorageTimeArr = (timeKey: string, value: string | number) => {
    let arr1 = getStorageTimeArr(timeKey);
    arr1.push(`${value}`);
    // 不可无限设置
    if (arr1.length >= 30) {
        arr1 = arr1.slice(-30);
    }
    const str1 = arr1.join(',');
    localStorage.setItem(timeKey, str1);
};
// 检查c类下是否需要展示:每x小时最多展示x次
const getStorageCIsShow = (timeKey: string, nowTimes: number, displayTypeTimeC: string, displayTypeNumC: number) => {
    const flag = false;
    const arr1 = getStorageTimeArr(timeKey);
    const times2 = nowTimes - oneHoursTimes * Number(`${displayTypeTimeC}`);

    const arr2 = arr1.filter((item: string) => {
        return Number(item) > times2; //保存的上次访问时间,要大于目标时间
    });

    if (arr2.length < displayTypeNumC) {
        // 没有达到次数
        return true;
    }
    return flag;
};
// 展示弹窗
const getWinShow = (id: string, timeKey?: string, nowTimes?: number | string) => {
    const elem = document.querySelector(`.glo-editor-modal-${id}`);
    if (elem) {
        elem.classList.add('glo-editor-modal-show');
        if (nowTimes && timeKey) {
            setStorageTimeArr(timeKey, nowTimes);
        }
    }
};
export default function SetDisplay({ id, data = {} }: any) {
    const { displayType, displayTypeNumB, displayTypeTimeC, displayTypeNumC } = data;
    const { locale, pathname = '' } = getLocal();

    useEffect(() => {
        const initFn = () => {
            if (pathname.includes('/tempedit') || pathname.includes('/preview')) {
                return false;
            }
            const timeKey = `win_${locale}_${id}`;
            const nowTimes = Date.now();
            const storageTimeArr = getStorageTimeArr(timeKey);
            const nowLen = storageTimeArr.length;
            if (displayType === 'A') {
                //每次进入都展示
                getWinShow(id);
            } else if (displayType === 'B' && displayTypeNumB) {
                //每个访问最多展示x次
                if (displayTypeNumB > nowLen) {
                    getWinShow(id, timeKey, nowTimes);
                }
            } else if (displayType === 'C' && displayTypeTimeC && displayTypeNumC) {
                //每x小时最多展示x次
                const isShowWin = getStorageCIsShow(timeKey, nowTimes, displayTypeTimeC, displayTypeNumC);
                if (isShowWin) {
                    getWinShow(id, timeKey, nowTimes);
                }
            }
        };
        setTimeout(initFn, 2000);
    }, []);
    return null;
}
