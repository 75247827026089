'use client';
import EmarsysWin from 'lowcode/common/BarWin/EmarsysWin';
import ImgLinkWin from 'lowcode/common/BarWin/ImgLinkWin';
import SalesPromotionAWin from 'lowcode/common/BarWin/SalesPromotionAWin';
import SalesPromotionBWin from 'lowcode/common/BarWin/SalesPromotionBWin';
import GloLayout from 'lowcode/common/GloLayout';
import SetDisplay from './SetDisplay';
import styles from './index.module.scss';

export default function Content({ id, data = {}, isEditStatus = false }: any) {
    const { barType } = data;
    return (
        <GloLayout className={`${styles.content} editor-temp glo-win-common temp-${id}`} id={id}>
            {barType === 'ImgLinkWin' && <ImgLinkWin id={id} data={data} isEditStatus={isEditStatus} />}
            {barType === 'SalesPromotionAWin' && <SalesPromotionAWin id={id} data={data} isEditStatus={isEditStatus} />}
            {barType === 'SalesPromotionBWin' && <SalesPromotionBWin id={id} data={data} isEditStatus={isEditStatus} />}
            {barType === 'EmarsysWin' && <EmarsysWin id={id} data={data} isEditStatus={isEditStatus} />}
            <SetDisplay id={id} data={data} />
        </GloLayout>
    );
}
