'use client';
import EditorImg from 'lowcode/components/EditorImg';
import EditorLink from 'lowcode/components/EditorLink';
import IconClose from '../IconClose';
import ClientStyle from '../ClientStyle';
import styles from './index.module.scss';
export default function Content({ id, data = {}, isEditStatus }: any) {
    const { label, title, subTitle, desc, specialText, buyText, buyLink, moreText, moreLink, winPc, winH5 } = data;

    return (
        <>
            <ClientStyle id={id} data={data} />
            <div className={`glo-editor-modal${isEditStatus ? ' glo-editor-modal-show' : ''} ${styles.content} glo-editor-modal-${id}`}>
                <div className="glo-editor-modal-wrap">
                    <div className="glo-editor-modal-content">
                        <div className="sales-bg">
                            <EditorImg pc={winPc} h5={winH5} />
                        </div>
                        <IconClose id={id} />
                        <div className="glo-editor-modal-body sales-messages">
                            {label && <div className="sales-label">{label}</div>}
                            {subTitle && <div className="sales-subTitle">{subTitle}</div>}
                            {title && <div className="sales-title">{title}</div>}
                            {desc && <div className="sales-desc">{desc}</div>}
                            <div className="sales-btns">
                                {buyText && buyLink && (
                                    <EditorLink href={buyLink} className="sales-btn sales-btn-buy">
                                        {buyText}
                                    </EditorLink>
                                )}
                                {moreText && moreLink && (
                                    <EditorLink href={moreLink} className="sales-btn sales-btn-more">
                                        {moreText}
                                    </EditorLink>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
