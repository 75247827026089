'use client';
import EditorImg from 'lowcode/components/EditorImg';
import EditorLink from 'lowcode/components/EditorLink';
import IconClose from '../IconClose';
import ClientStyle from '../ClientStyle';
import styles from './index.module.scss';

export default function Content({ id, data = {}, isEditStatus }: any) {
    const { label, title, subTitle, desc, specialText, buyText, buyLink, moreText, moreLink, winPc, winH5 } = data;

    return (
        <>
            <ClientStyle id={id} data={data} />
            <div className={`glo-editor-modal${isEditStatus ? ' glo-editor-modal-show' : ''} ${styles.content} glo-editor-modal-${id}`}>
                <div className="glo-editor-modal-wrap">
                    <div className="glo-editor-modal-content">
                        <EditorLink className="sales-bg" href={buyLink}>
                            <EditorImg pc={winPc} h5={winH5} />
                        </EditorLink>
                        <IconClose id={id} />
                    </div>
                </div>
            </div>
        </>
    );
}
